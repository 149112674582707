import React, { useState } from 'react';
import './App.css';

function App() {
    const [showBasicCard, setShowBasicCard] = useState(false);
    const [showSurveyCard, setShowSurveyCard] = useState(false);
    const [showScanCard, setShowScanCard] = useState(false);    
    const handleGetStartedClick = () => { setShowBasicCard(true); };
    const handleNextStepBackClick = () => { setShowBasicCard(false); setShowSurveyCard(false); setShowScanCard(false);};
    const handleNextStepClick = () => { setShowBasicCard(false); setShowSurveyCard(true); setShowScanCard(false);};
    const handleReadyBackClick = () => { setShowBasicCard(true); setShowSurveyCard(false); setShowScanCard(false); };
    const handleReadyClick = () => { setShowBasicCard(false); setShowSurveyCard(false); setShowScanCard(true); };
    const handleSubmitBackClick = () => { setShowBasicCard(false); setShowSurveyCard(true); setShowScanCard(false); };
    const handleSubmitClick = () => { setShowBasicCard(false); setShowSurveyCard(false); setShowScanCard(false); 
      
      var Airtable = require('airtable');
      var apiKey = process.env.REACT_APP_AIRTABLE_API_KEY;
      var base = new Airtable({ apiKey: apiKey }).base('appXEJOKXQ7qxxZnf');

        base('remodal').create([
          {
            "fields": {
              "name": name,
              "email": email,
              "address": address,
              "phoneNumber": phoneNumber,
              "homeType": homeType,
              "selectedStepFlooring" : selectedStepFlooring,
              "selectedStepShowerTile" : selectedStepShowerTile,
              "selectedStepShowerDoor" : selectedStepShowerDoor,
              "selectedStepTub" : selectedStepTub,
              "selectedStepToilet" : selectedStepToilet,
              "selectedStepVanityMirror" : selectedStepVanityMirror,
              "selectedStepLighting" : selectedStepLighting,
              "selectedStepHardware" : selectedStepHardware,
              "selectedStepPaintTrim" : selectedStepPaintTrim,
            }
          },
        ], function(err : string, records : string) {
            if (err) { console.error(err); return; }
          });
  
  };
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [homeType, setHomeType] = useState('');

  const [selectedStepFlooring, setSelectedStepFlooring] = useState('standard');
  const [selectedStepShowerTile, setSelectedStepShowerTile] = useState('standard');
  const [selectedStepShowerDoor, setSelectedStepShowerDoor] = useState('standard');
  const [selectedStepTub, setSelectedStepTub] = useState('standard');
  const [selectedStepToilet, setSelectedStepToilet] = useState('standard');
  const [selectedStepVanityMirror, setSelectedStepVanityMirror] = useState('standard');
  const [selectedStepLighting, setSelectedStepLighting] = useState('standard');
  const [selectedStepHardware, setSelectedStepHardware] = useState('standard');
  const [selectedStepPaintTrim, setSelectedStepPaintTrim] = useState('standard');

  const handleSliderChangeFlooring = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.valueAsNumber;
    let step;
    if (value < 33) { step = 'standard'; } else if (value >= 33 && value < 67) { step = 'premium'; } else { step = 'treat yourself'; } setSelectedStepFlooring(step); 
  };

  const handleSliderChangeShowerTile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.valueAsNumber;
    let step;
    if (value < 33) { step = 'standard'; } else if (value >= 33 && value < 67) { step = 'premium'; } else { step = 'treat yourself'; } setSelectedStepShowerTile(step); 
  };

  const handleSliderChangeShowerDoor = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.valueAsNumber;
    let step;
    if (value < 33) { step = 'standard'; } else if (value >= 33 && value < 67) { step = 'premium'; } else { step = 'treat yourself'; } setSelectedStepShowerDoor(step); 
  };

  const handleSliderChangeTub = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.valueAsNumber;
    let step;
    if (value < 33) { step = 'standard'; } else if (value >= 33 && value < 67) { step = 'premium'; } else { step = 'treat yourself'; } setSelectedStepTub(step); 
  };

  const handleSliderChangeToilet = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.valueAsNumber;
    let step;
    if (value < 33) { step = 'standard'; } else if (value >= 33 && value < 67) { step = 'premium'; } else { step = 'treat yourself'; } setSelectedStepToilet(step); 
  };

  const handleSliderChangeVanityMirror = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.valueAsNumber;
    let step;
    if (value < 33) { step = 'standard'; } else if (value >= 33 && value < 67) { step = 'premium'; } else { step = 'treat yourself'; } setSelectedStepVanityMirror(step); 
  };

  const handleSliderChangeLighting = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.valueAsNumber;
    let step;
    if (value < 33) { step = 'standard'; } else if (value >= 33 && value < 67) { step = 'premium'; } else { step = 'treat yourself'; } setSelectedStepLighting(step); 
  };

  const handleSliderChangeHardware = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.valueAsNumber;
    let step;
    if (value < 33) { step = 'standard'; } else if (value >= 33 && value < 67) { step = 'premium'; } else { step = 'treat yourself'; } setSelectedStepHardware(step); 
  };

  const handleSliderChangePaintTrim = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.valueAsNumber;
    let step;
    if (value < 33) { step = 'standard'; } else if (value >= 33 && value < 67) { step = 'premium'; } else { step = 'treat yourself'; } setSelectedStepPaintTrim(step); 
  };

  return (
    <div className="App">
      <header className="App-header">remodal.ai (beta)</header>
        <div className="App-body">
          <div className="App-imageCard">{/* Add your image content here */}</div>
            <div className="App-card">
              <h1 className="App-title">remodal</h1>
              <p className="App-para">
                Get the bathroom you want: quick, painless, easy. The first modern digital-native remodel company with
                accurate transparent pricing, reliable service, and high-end design.
              </p>
                <button className="App-button" onClick={handleGetStartedClick}>get started</button>
            </div>

        {showBasicCard && (
          <div className={`App-basicCardOverlay ${showBasicCard ? '' : 'exit'}`}>
            <div className={`App-basicCard ${showBasicCard ? '' : 'exit'}`}>
                <button className="App-backButton" onClick={handleNextStepBackClick}>
                  <img src="back.png" alt="Back"  style={{ width: '30px', height: '30px' , marginLeft: '-5px' }} />
                </button>
              <h2 className="App-basicCardTitle">lets get some basics...</h2>
              <div className="App-inputContainer">
                <input id="nameInput" name="nameInput" type="text" placeholder="your name" className="App-basicCardInput" value={name} onChange={(e) => setName(e.target.value)}/>
                <input id="emailInput" name="emailInput" type="email" placeholder="your email" className="App-basicCardInput" value={email} onChange={(e) => setEmail(e.target.value)} />
                <input id="addressInput" name="addressInput" type="text" placeholder="address of remodel" className="App-basicCardInput" value={address} onChange={(e) => setAddress(e.target.value)} />
                <input id="phoneNumberInput" name="phoneNumberInput" type="tel" placeholder="contact phone number" className="App-basicCardInput" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                    <select id="homeTypeInput" name="homeTypeInput" value={homeType} onChange={(e) => setHomeType(e.target.value)} className="App-basicCardSelect" >
                      <option value="unselected">select home type</option>
                      <option value="condominium">condominium</option>
                      <option value="apartment">apartment</option>
                      <option value="singlyfamilyhome">single family home</option>
                      <option value="townhome">townhome</option>
                      <option value="commercial">commercial</option>
                    </select>
              </div>
                <button className="App-button" onClick={handleNextStepClick}>next step</button>
              </div>
          </div>
        )}

        {showSurveyCard && (
          <div className={`App-surveyCardOverlay ${showBasicCard ? '' : 'enter'}`}>
            <div className={`App-surveyCard ${showSurveyCard ? 'enter' : ''}`}>
                <button className="App-backButton" onClick={handleReadyBackClick}>
                  <img src="back.png" alt="Back"  style={{ width: '30px', height: '30px' , marginLeft: '-5px' }} />
                </button>
            <h2 className="App-surveyCardTitle">choose your style...</h2>
              <div className="App-surveyCardItem">
                <h2 className="App-surveyCardItemTitle">flooring</h2>
                <label className="App-surveyCardItemValue">{selectedStepFlooring}</label>
                <div className="range-slider">
                  <input type="range" min="0" max="3" step="1" value={selectedStepFlooring === 'standard' ? '0' : selectedStepFlooring === 'premium' ? '2' : '3'} onChange={handleSliderChangeFlooring} />
                </div>
              </div>
              <div className="App-surveyCardItem">
                <h2 className="App-surveyCardItemTitle">shower area</h2>
                <label className="App-surveyCardItemValue">{selectedStepShowerTile}</label>
                <div className="range-slider">
                  <input type="range" min="0" max="100" step="1" value={selectedStepShowerTile === 'standard' ? '0' : selectedStepShowerTile === 'premium' ? '50' : '100'} onChange={handleSliderChangeShowerTile} />
                </div>
              </div>
              <div className="App-surveyCardItem">
                <h2 className="App-surveyCardItemTitle">tub</h2>
                <label className="App-surveyCardItemValue">{selectedStepTub}</label>
                <div className="range-slider">
                  <input type="range" min="0" max="100" step="1" value={selectedStepTub === 'standard' ? '0' : selectedStepTub === 'premium' ? '50' : '100'} onChange={handleSliderChangeTub} />
                </div>
              </div>
              <div className="App-surveyCardItem">
                <h2 className="App-surveyCardItemTitle">toilet</h2>
                <label className="App-surveyCardItemValue">{selectedStepToilet}</label>
                <div className="range-slider">
                  <input type="range" min="0" max="100" step="1" value={selectedStepToilet === 'standard' ? '0' : selectedStepToilet === 'premium' ? '50' : '100'} onChange={handleSliderChangeToilet} />
                </div>
              </div>  
              <div className="App-surveyCardItem">
              <h2 className="App-surveyCardItemTitle">powder area</h2>
              <label className="App-surveyCardItemValue">{selectedStepVanityMirror}</label>
              <div className="range-slider">
                  <input type="range" min="0" max="100" step="1" value={selectedStepVanityMirror === 'standard' ? '0' : selectedStepVanityMirror === 'premium' ? '50' : '100'} onChange={handleSliderChangeVanityMirror} />
                </div>
              </div>
              <div className="App-surveyCardItem">
                <h2 className="App-surveyCardItemTitle">lighting</h2>
                <label className="App-surveyCardItemValue">{selectedStepLighting}</label>    
                <div className="range-slider">
                  <input type="range" min="0" max="100" step="1" value={selectedStepLighting === 'standard' ? '0' : selectedStepLighting === 'premium' ? '50' : '100'} onChange={handleSliderChangeLighting} />
                </div>          
              </div>  
              <div className="App-surveyCardItem">
                <h2 className="App-surveyCardItemTitle">hardware</h2>
                <label className="App-surveyCardItemValue">{selectedStepHardware}</label>
                <div className="range-slider">
                  <input type="range" min="0" max="100" step="1" value={selectedStepHardware === 'standard' ? '0' : selectedStepHardware === 'premium' ? '50' : '100'} onChange={handleSliderChangeHardware} />
                </div>
              </div>
              <div className="App-surveyCardItem">
                <h2 className="App-surveyCardItemTitle">paint / trim</h2>
                <label className="App-surveyCardItemValue">{selectedStepPaintTrim}</label>
                <div className="range-slider">
                  <input type="range" min="0" max="100" step="1" value={selectedStepPaintTrim === 'standard' ? '0' : selectedStepPaintTrim === 'premium' ? '50' : '100'} onChange={handleSliderChangePaintTrim} />
                </div>
              </div>  
              <button className="App-button" onClick={handleReadyClick}>
                ready
              </button>  
            </div>
          </div>
        )}

        {showScanCard && (
          <div className={`App-scanCardOverlay ${showScanCard ? '' : 'enter'}`}>
            <div className={`App-scanCard ${showScanCard ? '' : 'enter'}`}>
              <button className="App-backButton" onClick={handleSubmitBackClick}>
                  <img src="back.png" alt="Back"  style={{ width: '30px', height: '30px' , marginLeft: '-5px' }} />
              </button>
              <h2 className="App-scanCardTitle">scan your bathroom....</h2>
              <p> On the next step, you will need to download the Matterport app and scan your current bathroom. Don’t worry, it takes less than 5 minutes. Here’s a video showing exactly what you need to do...</p>
              
              <button className="App-button" onClick={handleSubmitClick}>
                submit
              </button>
            </div>
          </div>
        )}

      </div>
    </div>
  );
}

export default App;
